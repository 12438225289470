<template>
	<div class="body">
		<div class="search-form">
			<Row align="middle" :gutter="10">
				<Col>
				<Input type="text" placeholder="插件名称" v-model="ruleForm.name"></Input>
				</Col>
				<Col>
				<Button type="primary" @click="init()">搜索</Button>
				</Col>
			</Row>
		</div>
		<div class="table-body">
			<Table :data="data" :columns="columns" :loading="table_loading">
				<template slot="icon" slot-scope="{row}">
					<div style="margin-top: 5px;">
						<img :src="fullImage(row.icon)" alt="" class="thumb">
					</div>
				</template>
				<template slot="money" slot-scope="{row}">
					<div>{{ row.money == 0?'免费':row.money }}</div>
				</template>
				<template slot="operation" slot-scope="{row}">
					<Button @click="edit_save(row.id)" style="margin-right: 10px;">编辑</Button>
					<Button type="primary" @click="open_version(row.id)">版本列表</Button>
				</template>
			</Table>
			<Page :total="ruleForm.total" :current="ruleForm.current" :page-size="ruleForm.page_size"
				:page-size-opts="[10,15,20]" :show-total="true" style="text-align: right;margin-top: 10px;"
				@on-change="set_page" @on-page-size-change="set_limit" show-sizer></Page>
		</div>
		<versionListVue ref="versionList"></versionListVue>
	</div>
</template>

<script>
	import versionListVue from './versionList.vue'
	export default {
		data() {
			return {
				table_loading: false,
				totalUrl: this.serveUrl,
				//查询条件
				ruleForm: {
					name: '', //查询组件名称
					current: 1, //当前页码
					total: 0, //总数
					page_size: 10, //每页条数
				},
				//数据
				data: [{
					id: 1,
					name: '积分商城',
					icon: 'https://adm.api.jk125.com/image/plugin/10c4082a6db6f836e384a046c6021d6.jpg',
					default_thumb: 'https://adm.api.jk125.com/image/plugin/10c4082a6db6f836e384a046c6021d6.jpg',
					simple: '积分购物',
					money: 0,
					create_time: '2021-1-1 05:14:59'
				}],
				//表格表头
				columns: [{
					title: '插件名称',
					key: 'name',
					minWidth: 100,
					align: 'center'
				}, {
					title: '商户展示图标',
					slot: 'icon',
					minWidth: 150,
					align: 'center'
				}, {
					title: '简介',
					key: 'simple',
					minWidth: 100,
					align: 'center'
				}, {
					title: '购买价格（元）',
					slot: 'money',
					minWidth: 100,
					align: 'center'
				}, {
					title: '创建时间',
					key: 'create_time',
					minWidth: 150,
					align: 'center'
				}, {
					title: '操作',
					slot: 'operation',
					minWidth: 200,
					align: 'center'
				}]
			}
		},
		components: {
			versionListVue
		},
		created() {
			this.init()
		},
		methods: {
			init() {

				this.table_loading = true

				let _this = this

				this.requestApi('/adm/get_plugin_list', {
					ruleForm: this.ruleForm
				}).then(
					(res) => {
						_this.table_loading = false
						//表格数据
						_this.data = res.data.data
						//当前页码
						_this.ruleForm.current = res.data.current_page
						//总条数
						_this.ruleForm.total = res.data.total
						//每页条数
						_this.ruleForm.page_size = res.data.per_page
					}
				)
			},
			set_page(e) {
				this.ruleForm.current = e
				this.init()
			},
			set_limit(e) {
				this.ruleForm.page_size = e
				this.init()
			},
			edit_save(id) {
				this.toUrl('/plugin/edit', {
					id: id
				})
			},
			open_version(id) {
				this.$refs['versionList'].open(id)
			}
		}
	}
</script>

<style scoped="scoped">
	.body {
		background-color: #fff;
		padding: 10px;
	}

	.table-body {
		margin-top: 10px;
	}

	.thumb {
		width: 50px;
		height: 50px;
	}
</style>